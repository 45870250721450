$color-black:#222;
$color-grey:rgba(34, 34, 34, .5);
$color-very-light-grey:#f9f9f9;
$color-orange:#FF671F;
$color-rouge:#B45638;
$color-vert:#7BDE45;
$color-bleu:#41B6E6;
$color-jaune:#FFCD00;

$master-header-height: 11rem;
$master-header-picture-height:22rem;
$master-header-picture-width:22rem;
$master-header-picture-border:1rem;

$page-header-height:12rem;
$page-header-margin-top:2.5rem;

$body-width: 100%;
$body-max-width: 100%;

$font-family: Roboto;

$navbar-height: 5.6rem;

$font-size-xs: .6rem;
$font-size-s: .8rem;
$font-size: 1rem;
$font-size-l: 1.2rem;
$font-size-big-s: 1.4rem;
$font-size-big-m: 1.6rem;
$font-size-big: 1.8rem;
$font-size-big-l: 2rem;

$button-font-size:1rem;
$button-font-size-m: 1.2rem;
$button-font-size-s: 1.1rem;
$button-font-size-xs: 1rem;


$font-weight:normal;
$font-weight-light:300;

$font-color:#222;

$background-color:#F6F6F6;
$button-color:#FF671F;
$button-hover-color:#222;
$button-font-color:#fff;

$section-padding: 1.5em;
$section-margin-vertical: 1.5em;
$section-margin-horizontal: 0.75em;
$section-height: 45vh;

$main-panel-side-padding: 155px;
$pictureAndborderWidth:Calc( #{$master-header-picture-width} + #{$master-header-picture-border});

add-btn {
  position: fixed;
  z-index: 99;
  bottom: 6rem;
  right: 4rem;
}

.box-shadow{
  // -webkit-box-shadow: 0px 15px 30px 0 rgba(28, 23, 44,.12);
  // -moz-box-shadow: 0px 15px 30px 0 rgba(28, 23, 44,.12);
  // box-shadow: 0px 15px 30px 0 rgba(28, 23, 44,.12);
  -webkit-box-shadow: 0 0.8rem 1.2rem rgba(34, 34, 34, 0.04);
  -moz-box-shadow: 0 0.8rem 1.2rem rgba(34, 34, 34, 0.04);
  box-shadow:0 0.8rem 1.2rem rgba(34, 34, 34, 0.04);
}

button:focus, input:focus {
  outline: none;
}

.content-block{
  box-shadow:0 0.8rem 1.2rem rgba(34, 34, 34, 0.04);
  border-radius: .8rem;
  overflow: auto;
}

.action-row{
  padding: 0.5em;
  width: 100%;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  display: block;
  .back-btn{
    margin-right:10px;
  }
  .custom-button.flat{
    cursor: pointer;
    text-transform: uppercase;
    font-weight: $font-weight;
    img{
        height: 44px;
        width: 44px;
        vertical-align: top;
    }
    span{
        height: 44px;
        line-height: 44px;
    }
}
  .save-btn{margin-left: 10px;}
}

.content{
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 3rem 5rem;
  border-radius: 0.8rem;
  font-size: 1.7rem;
  box-shadow: 0 .8rem 1.4rem rgba(34, 34, 34, .04);
  display: inline-block;
}

.custom-button{
  border: none;
  height: 5rem;
  line-height: 5rem;
  border-radius: 2.5rem;
  background-color:$color-orange;
  color: #fff;
  padding: 0 5rem;
  font-size: $button-font-size;
  text-transform: uppercase;
  letter-spacing: .1rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-weight: 600!important;

  .mat-ripple-element{
    background-color: #fff;
    opacity: .1;
  }
}

.custom-button:hover{
  background-color:$button-hover-color;
}

.custom-button.flat{
  background-color:transparent;
  color:$color-black;
  border-radius: 0;

  &:hover{
    color:#fff;
    background-color: $color-black;
  }

}

//Override Angular Material css
.custom-checkbox{
  mat-checkbox{
    .mat-checkbox-label{
      color:$color-grey;
    }
  }

  mat-checkbox.mat-checkbox-checked, mat-checkbox.mat-checkbox-indeterminate{
    .mat-checkbox-background {
      background-color:$color-orange;
    }
  }

  mat-checkbox.mat-checkbox-checked.mat-checkbox-disabled, mat-checkbox.mat-checkbox-indeterminate.mat-checkbox-disabled{
    .mat-checkbox-frame{
      border-color:rgba(255, 103, 31, 0.1);
    }
    .mat-checkbox-background {
      background-color:rgba(255, 103, 31, 0.5);
    }
  }
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: $color-orange;
}
.mat-form-field.mat-focused .mat-form-field-label{
  color: $color-black;
}

.mat-checkbox-inner-container{
  width:18px!important;
  height: 18px!important;
}

snack-bar-container{
  background-color: transparent!important;
  color: #222!important;
  max-width: 100%!important;
  box-shadow: none;
}

.mat-snack-bar-container{
  max-width:100%!important;
  box-shadow: none;
}

mat-error{
  display: inline-block;
  margin-right: 0;
}
mat-error:not(:first-child):before{
  content:" et ";
  white-space: pre;
}


.smag-account-filter{
  .search-box {
    .search-input{
      box-shadow: none!important ;
      border: 1px solid rgba(34,34,34,.1)!important;
    }
  }
}

// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "./styles/common.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$color-black:#222;
$color-grey:rgba(34, 34, 34, .5);
$color-very-light-grey:#f9f9f9;
$color-orange:#FF671F;
$color-rouge:#B45638;
$color-vert:#7BDE45;
$color-bleu:#41B6E6;
$color-jaune:#FFCD00;
$button-font-size:1.4rem;
$button-hover-color:#222;
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$smag-reporter-app-primary: mat.define-palette(mat.$indigo-palette);
$smag-reporter-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$smag-reporter-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$smag-reporter-app-theme: mat.define-light-theme((
  color: (
    primary: $smag-reporter-app-primary,
    accent: $smag-reporter-app-accent,
    warn: $smag-reporter-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($smag-reporter-app-theme);

/* You can add global styles to this file, and also import other style files */

html, *{

  box-sizing:border-box;

}



html {

  background-color: $background-color;

  width: 100%;

  height: 100%;

  min-height: 100%;

  font-size: 10px;

}



body {

  font-family: $font-family;

  font-weight: $font-weight;

  color: $font-color;

  width: $body-width;

  max-width: $body-max-width;

  background-color: $background-color;

  height: 100%;

  min-height: 100%;

  margin: 0 auto;

}


snack-bar-container {
  background-color: transparent !important;
  color: #222 !important;
  max-width: 100% !important;
  box-shadow: none;
}

.mat-snack-bar-container {
  max-width:100% !important;
  box-shadow: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: unset;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  content: unset !important;
}

section{
  display: block;
  margin: 1.5em .75em;
  padding: 1.5em;
  background-color: #f6f6f6;
  border-radius: 8px;
  height: 100%;
}

.custom-button{
  height: 5rem;
  line-height: 5rem;
  border-radius: 2.5rem;
  background-color:$color-orange;
  color: #fff;
  padding: 0 5rem;
  font-size: $button-font-size;
  text-transform: uppercase;
  letter-spacing: .1rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-weight: 600!important;
  border: 0px none;

  .mat-ripple-element{
    background-color: #fff;
    opacity: .1;
  }
}

button:focus, input:focus {
  outline: none;
}


.custom-button:hover{
  background-color:$button-hover-color;
}

.custom-button.flat{
  background-color:transparent;
  color:$color-black;
  border-radius: 0;

  &:hover{
    color:#fff;
    background-color: $color-black;
  }

}
